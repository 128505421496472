import { AgentSearch, AgentSearchProps } from 'components/agent-search';
import { getThemeOverrideFromRequest } from 'utils/themes';
import { getStringFromRequestCookie } from 'utils/cookies';
import { CountryCode, CountryCodeList } from 'types/countries';
import HeadData from 'components/head-data';
import { ThemeNames } from 'types/themes';
import Layout from 'components/layout';
import { v4 as uuidv4 } from 'uuid';
import usePreventPrint from 'hooks/usePreventPrint';

import type { GetServerSidePropsContext } from 'next';
import { SITE_LOCATION_COOKIE_NAME } from 'constants/cookies';
import { themes } from 'themes';

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { location, country } = context.query;

  const sessionId = uuidv4();

  const themeName = getThemeOverrideFromRequest(context.req);
  const theme = themes[themeName];

  let title = 'Find a Local Real Estate Agent | eXp Realty®';
  let metaDescription = "Find real estate listings for sale in Canada. Search MLS real estate listings for homes, condos and properties on exprealty.ca, Canada's top real estate website.";

  if (theme) {
    title = `Find a Local Real Estate Agent ${theme.title}`;
    metaDescription = theme.metaDescription;
  }

  const storedSiteLocation = getStringFromRequestCookie(SITE_LOCATION_COOKIE_NAME, context.req) as CountryCode;
  const storedCountry = (
    country && typeof country === 'string' && (country === CountryCodeList.CANADA || country === CountryCodeList.UNITED_STATES))
    ? country
    : storedSiteLocation === CountryCodeList.UNITED_STATES ? CountryCodeList.UNITED_STATES : CountryCodeList.CANADA;

  let decodedLocation;

  if (location && typeof location === 'string' && location.length > 0) {
    decodedLocation = decodeURIComponent(location);
  }

  if (themeName === ThemeNames.ZOOCASA) {
    return {
      redirect: {
        permanent: true,
        destination: '/404',
      },
    };
  }

  return {
    props: {
      initialLocation: decodedLocation || null,
      initialCountry: storedCountry,
      sessionId,
      headerData: {
        title,
        metaDescription,
      },
    },
  };
}

export default function AgentsPage(props: AgentSearchProps) {
  usePreventPrint();

  return (
    <Layout>
      <HeadData data={props.headerData} />
      <AgentSearch {...props} />
    </Layout>
  );
}